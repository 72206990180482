import {
  all,
  takeLatest,
  put,
  fork,
  call,
  select,
  race,
  take,
} from "redux-saga/effects";
import { logError } from "../../../helpers/utility";
import {
  checkDataOfAddingBatchItem,
  serializeListing,
  checkPriceLimit,
} from "../../../helpers/batch/utility";
import {
  searchAmazonProductsAPI,
  saveBatchItemAPI,
  updateBatchItemAPI,
	deleteBatchItemAPI,
	updateBatchDataAPI,
	sbybUserTrackingAPI,
  searchBatchItemSalesRankRange
} from "../../../helpers/batch/apis";
import { backendAPICall, apiEndpoints } from '../../../helpers/backend/api/client';

import { getListingDefaults } from "../../../helpers/settings_apis";

import appActions from "../../app/actions";
import printerActions from "../../print_service/actions";
import settingsActions from "../../../redux/settings/actions";
import authActions from "../../../redux/auth/actions";
import actions from "../actions";
import {
  batchIdSelector,
  batchMetadataSelector,
  batchListingDefaultsSelector,
  currentWorkingListingDataSelector,
  productsSelector,
	userDataSelector,
} from "../selector";

import { printerDefaultsSelector } from "../../settings/selector";


export function* productSearchResultsRequest() {
  yield takeLatest(actions.REQUEST_PRODUCT_SEARCH_RESULTS, function*(payload) {
    try {
      let { query, channel, existingProducts } = payload;
      const response = yield call(searchAmazonProductsAPI, query);
      if (response.data.error) {
        // @TODO: Add action to handle failure scenario
      } else {
        let searchResults = response.data.results.map(item => {
					item['query_string'] = query;
					return item;
				});
        yield put(actions.receiveProductSearchResults(searchResults));
        if (searchResults.length === 1) {
          yield put(
            actions.selectProductSearchResultAndInitializeListing(
              searchResults[0],
              channel,
              existingProducts,
            )
          );
        } else {
          yield put(actions.setCurrentFlow("search_results_display"));
          if (searchResults.length === 0) {
            yield put(actions.setCurrentFlow("created_listings_display"));
            yield put(actions.clearSearchQuery());
            yield put(actions.setSearchResultIsEmpty(true));

          }
        }
      }
    } catch (err) {
      yield put(actions.requestProductSearchResultsFailed());
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(err, {
        tags: {
          exceptionType: actions.REQUEST_PRODUCT_SEARCH_RESULTS,
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* cancelListingCreationFlow() {
  yield takeLatest(actions.CANCEL_LISTING_CREATION_FLOW, function*(payload) {
    try {
      yield put(actions.setCurrentFlow("created_listings_display"));
      yield put(actions.clearSearch());
      yield put(actions.clearCurrentWorkingListing());
      yield put(actions.resetWorkflowOptions());
    } catch (err) {
      yield put({
        type: actions.CANCEL_LISTING_CREATION_FLOW_ERROR
      });
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(err, {
        tags: {
          exceptionType: actions.CANCEL_LISTING_CREATION_FLOW_ERROR,
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* _selectProductSearchResultAndInitializeCurrentWorkingListing(payload) {
  try {
    let { searchResult, channel, existingProducts, printerHelper } = payload;
    yield put(actions.selectProductSearchResult(searchResult));
    yield put(actions.initializeCurrentWorkingListing());
    yield put(actions.getPricingData(searchResult.ASIN, channel, searchResult.category, searchResult.query_string));
		yield put(actions.pricingDataSbybTracking(
			{
				asin: searchResult.ASIN,
				category:searchResult.category,
				query_string: searchResult.query_string
			}
		));
		yield put(actions.pricingDataProfithuntTracking({	asin: searchResult.ASIN }));
    yield put(actions.updateCurrentListingWorkflowOptions('speedMode', true));
    yield put(actions.updateAddToBatchStatus(true));
    // yield put(actions.updateCurrentListingWorkflowOptions('showPricing', true));

    let matchingProduct = null;
    existingProducts.forEach(product => {
      if (product.asin === searchResult.ASIN) {
        matchingProduct = product;
      }
    });

    let canAddListing = false;
    if (matchingProduct) {
      yield put(actions.setCurrentEditableListing(matchingProduct, false));
      yield put(actions.updateModalDisplay("duplicate_asin_warning"));
    } else if (
      searchResult.replenishableListings &&
		searchResult.replenishableListings.length > 0 &&
		!searchResult.listWithNewMSKU
    ) {
      yield put(actions.updateModalDisplay("replishment_modal_warning"));
    } else {
      yield put(actions.setCurrentFlow("listing_creator_display"));
      canAddListing = true;
    }

    const { abort, suspend, priceCalculatedSuccess } = yield race({
      //pricingDataFailure: take(actions.GET_PRICING_DATA_FAILURE),
      priceCalculatedSuccess: take(actions.UPDATE_CALCULATED_PRICE),
      priceCalculatedError: take(actions.UPDATE_CALCULATED_PRICE_ERROR),
      abort: take(actions.SELECT_PRODUCT_SEARCH_RESULT_AND_INITIALIZE_CURRENT_WORKING_LISTING),
      suspend: take(actions.SUSPEND_SPEED_MODE),
    });
	  const batchListingDefaults = yield select(batchListingDefaultsSelector);
		const repricer_roi_number = batchListingDefaults.repricer_roi_number || 0;
		const repricer_roi_type= batchListingDefaults.repricer_roi_type || "$";
		yield put(actions.updateCurrentWorkingListingData(
			'repricer_roi_number', repricer_roi_number,	false));
		yield put(actions.updateCurrentWorkingListingData(
			'repricer_roi_type', repricer_roi_type, false));
		yield put(actions.batchItemProjectedProfit(
			{
				asin: searchResult.ASIN,
				price: parseFloat(batchListingDefaults.price) || 0,
				weight: searchResult.packageDimensions && searchResult.packageDimensions.Weight ? searchResult.packageDimensions.Weight : 0,
			}
		));
	  const listing_defaults = yield call(getListingDefaults);
	if(batchListingDefaults.shouldUseCustomSkuTemplate){
		if(batchListingDefaults.skuPrefix !== listing_defaults.data.data.sku_prefix){
		    yield put(actions.updateCurrentWorkingListingData(
				  "skuPrefix", batchListingDefaults.skuPrefix, false));
		} else {
		    yield put(actions.updateCurrentWorkingListingData(
				  "skuPrefix", listing_defaults.data.data.sku_prefix, false));
		}
	}

    let speedMode = false;
    if (priceCalculatedSuccess) {
      yield put(actions.updateCurrentWorkingListingData("price", priceCalculatedSuccess.price, false));
			const data = {
				asin: searchResult.ASIN,
				price: priceCalculatedSuccess.price,
				weight: searchResult.packageDimensions && searchResult.packageDimensions.Weight ? searchResult.packageDimensions.Weight : 0,
			}
      yield put(actions.batchItemProjectedProfit(data));

      if (!!batchListingDefaults && !batchListingDefaults.pricingOptions && !batchListingDefaults.gradingOptions && batchListingDefaults.condition !== "NoDefault") {
        speedMode = true;
      }
    }
		//yield put(actions.updateCurrentListingWorkflowOptions('showPricing', !priceCalculatedSuccess));
		//29-10-2019
		//fix as changing option on workflow tab do not change to speed mode
		//will need to test this out with real users
		yield put(actions.updateCurrentListingWorkflowOptions('showPricing', batchListingDefaults.pricingOptions));
		// allow us to maake user confirm on first item in batch
		const currentWorkingListingData = yield select(currentWorkingListingDataSelector);
		if(currentWorkingListingData.skuNumber <= 1){ speedMode = false; }
    yield put(actions.updateCurrentListingWorkflowOptions('speedMode', speedMode));
    yield put(actions.updateAddToBatchStatus(canAddListing && speedMode));
		if (abort || suspend) {
      return;
    }

		if (canAddListing && speedMode) {
      yield put(actions.tryAddingItemToBatch(
        null,
        printerHelper,
        false
      ));
    }
  } catch (err) {
    yield put(appActions.apiCallFailed("Error! Fetching api error"));
    logError(err, {
      tags: {
        exceptionType: "SELECT_PRODUCT_SEARCH_RESULT_AND_INITIALIZE_CURRENT_WORKING_LISTING_ERROR",
        batchId: yield select(batchIdSelector),
      }
    });
  }
}

export function* selectProductSearchResultAndInitializeCurrentWorkingListing() {
  yield takeLatest(
    actions.SELECT_PRODUCT_SEARCH_RESULT_AND_INITIALIZE_CURRENT_WORKING_LISTING,
    _selectProductSearchResultAndInitializeCurrentWorkingListing
  );
}

export function* saveListing() {
  yield takeLatest(actions.SAVE_LISTING, function*(payload) {
    try {
      const {
        listing,
        shipmentIdToCurrentBoxMapping,
        shipmentIdToBoxCountMapping,
        skuNumber,
        isHoldingAreaListing
      } = payload;

			if(listing.sku.length > 39){
        yield put(actions.saveListingFailure());
        yield put(appActions.apiCallFailed("Error! SKU length can be up to 40 characters long. Please review custom SKU template"));
				yield put(actions.updateAddToBatchStatus(false));
				return;
			}

      const batchMetadata = yield select(batchMetadataSelector);
      const batchId = batchMetadata.id;

      let pricingData = listing.pricingData;
      if (pricingData) {
        pricingData = {
          fees: pricingData.fees
        };
      }
      let {
        isGeneratedSku,
        ...updatedListing
      } = listing
      let data = {
        batchId,
        shipmentIdToCurrentBoxMapping, // can be null
        shipmentIdToBoxCountMapping, // can be null
        item: {
          ...updatedListing,
          pricingData: pricingData
        }
      };
      const response = yield call(saveBatchItemAPI, data);
      if (response?.data?.error) {
        yield put(actions.saveListingFailure());
        yield put(appActions.apiCallFailed("Error! Unable to save listing"));
      } else {
        yield put(
          actions.saveListingSuccess(
            updatedListing,
            skuNumber,
            isHoldingAreaListing
          )
        );
        updatedListing.fnsku = response.data?.data?.fnsku;
        listing.fnsku = response.data?.data?.fnsku;
        yield put(actions.setCurrentWorkingListing(updatedListing));
        const userData = yield select(userDataSelector);
        if(listing?.signal_replenished_item || userData?.settings?.print_sku_label_on_scan){
          yield put(
            actions.printListing(
              listing,
              listing.qty
            )
          );
        }
      }
      yield put(actions.updateAddToBatchStatus(false));
    } catch (err) {
      yield put(actions.updateAddToBatchStatus(false));
      yield put(appActions.apiCallFailed("Error! Unable to save listing"));
      logError(err, {
        tags: {
          exceptionType: actions.SAVE_LISTING_FAILURE,
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* saveListingSuccess() {
  yield takeLatest(actions.SAVE_LISTING_SUCCESS, function*(payload) {
    try {
      let {
        listing,
        skuNumber,
        isHoldingAreaListing
      } = payload;
      if (isHoldingAreaListing) {
        // @TODO: Alert the user about what the current warehouse is.
        yield put(actions.deleteHoldingAreaListing(listing.sku));
        yield put(
          appActions.apiCallSuccess(
            "Successfully Added the listing to your batch."
          )
        );
      } else {
        yield put(appActions.apiCallUserSoundNotificationSuccess());
        yield put(actions.incrementSKUNumber(skuNumber));

				const userData = yield select(userDataSelector);
				if(userData && userData.settings.sku_lock_sufix_activated){
					const ud = userData;
					if(ud.settings.sku_lock_sufix_integer){
						ud.settings.sku_lock_sufix_integer = ud.settings.sku_lock_sufix_integer + 1;
					} else {
						ud.settings.sku_lock_sufix_integer = 1;
					}
					yield put(authActions.updateUserData(ud));
					const data = {sku_lock_sufix_integer: ud.settings.sku_lock_sufix_integer}
					yield put(settingsActions.updateUserSettings(data));
				}

        yield put(actions.setCurrentFlow("created_listings_display"));
      }

      yield put(actions.addCurrentWorkingListingToBatch());
      yield put(actions.clearSearch());
      yield put(actions.clearCurrentWorkingListing());
      yield put(actions.resetWorkflowOptions());
      /*
      yield put(
        actions.printListing(
          listing,
          listing.qty
        )
      );
      */
      yield put(actions.setCurrentPage(1));
    } catch (err) {
      yield put(appActions.apiCallFailed("Error! Unable to save listing"));
      logError(err, {
        tags: {
          exceptionType: actions.SAVE_LISTING_FAILURE,
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* editListing() {
  yield takeLatest(actions.EDIT_LISTING, function*(payload) {
    try {
      const {
        batchId,
        listing,
        qtyIncreased,
        isHoldingAreaListing
      } = payload;
      let pricingData = listing.pricingData;
      if (pricingData) {
        pricingData = {
          fees: pricingData.fees
        };
      }
      let data = {
        batchId,
        item: {
          ...listing,
          pricingData: pricingData
        }
      };

      const response = yield call(updateBatchItemAPI, data);
      let responseData = response.data;

      if (responseData.error) {
        yield put(actions.editListingFailure());
      } else {
        yield put(
          actions.editListingSuccess(
            listing,
            qtyIncreased,
          )
        );
        if (isHoldingAreaListing) {
          yield put(actions.deleteHoldingAreaListing(listing.sku));
          yield put(
            appActions.apiCallSuccess(
              "Successfully Updated the listing in your batch."
            )
          );
        }
      }
    } catch (err) {
      yield put(appActions.apiCallFailed("Error! Unable to edit listing"));
      logError(err, {
        tags: {
          exceptionType: actions.EDIT_LISTING_FAILURE,
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* editListingSuccess() {
  yield takeLatest(actions.EDIT_LISTING_SUCCESS, function*(payload) {
    try {
      const {
        listing,
        qtyIncreased,
      } = payload;
      if (qtyIncreased > 0) {
        yield put(
          actions.printListing(
            listing,
            qtyIncreased
          )
        );
        yield put(actions.setCurrentFlow("created_listings_display"));
      }
    yield put(actions.clearSearch());
    } catch (err) {
      logError(err, {
        tags: {
          exceptionType: actions.EDIT_LISTING_FAILURE,
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* deleteListing() {
  yield takeLatest(actions.DELETE_LISTING, function*(payload) {
    try {
      let { sku } = payload.listing;
      let data = {
        batchId: payload.batchId,
        sku
      };
      const response = yield call(deleteBatchItemAPI, data);
      const responseData = response.data;
      if (responseData.error) {
        yield put(actions.deleteListingFailure());
      } else {
        yield put(actions.deleteListingSuccess(sku));
      }
      yield put(actions.clearCurrentEditableListing());
    } catch (err) {
      yield put(appActions.apiCallFailed("Error! Unable to delete listing!"));
      yield put(actions.clearCurrentEditableListing());
      logError(err, {
        tags: {
          exceptionType: actions.DELETE_LISTING_FAILURE,
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* printListing() {
  yield takeLatest(actions.PRINT_LISTING, function*(payload) {
    try {
      const {
        listing,
        qty
      } = payload;
      const batchMetadata = yield select(batchMetadataSelector);
      const printerDefaults = yield select(printerDefaultsSelector);
			const userData = yield select(userDataSelector);

      if (printerDefaults
            && batchMetadata?.is_mf_ticketing_enabled
            && batchMetadata?.channel === "DEFAULT"
            && batchMetadata.printWhileScan
      ){
        yield put(printerActions.print(listing, qty));
        return;
      }
      if (
        printerDefaults
          && batchMetadata.printWhileScan
          && listing?.signal_replenished_item
      ){
        yield put(printerActions.print(listing, qty));
        return;
      }
      if (
        printerDefaults
          && userData?.settings?.print_sku_label_on_scan
      ){
        yield put(printerActions.print({...listing, ...{fnsku: listing.sku}}, qty));
        return;
      }
    } catch (err) {
      logError(err, {
        tags: {
          exceptionType: "PRINT_LISTING_ERROR",
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* tryAddingItemToBatch() {
  yield takeLatest(actions.TRY_ADDING_ITEM_TO_BATCH, function*(payload) {
    try {
      const {
        isHoldingAreaListing,
      } = payload;
      let { listing } = payload;
      const printerDefaults = yield select(printerDefaultsSelector);
      let currentWorkingListingData = yield select(currentWorkingListingDataSelector);
      const batchMetadata = yield select(batchMetadataSelector);
      const { id, channel } = batchMetadata;
      const batchListingDefaults = yield select(batchListingDefaultsSelector);

      const { shippingTemplate } = batchListingDefaults;
      const products = yield select(productsSelector);
       if (!listing) {
        listing = checkPriceLimit(currentWorkingListingData, batchListingDefaults);
      }
      const signal_replenished_item = listing?.signal_replenished_item;
      listing = serializeListing(listing);
      listing.signal_replenished_item = signal_replenished_item;
      if (channel && channel === "DEFAULT") {
        if (shippingTemplate === "" || shippingTemplate === null ) {
          yield put(appActions.apiCallFailed("Shipping Template is missing, please check and try gain."));
          return;
        }
      }
      const checkDataOfAddingBatchItemMessage = checkDataOfAddingBatchItem(listing);
      if (checkDataOfAddingBatchItemMessage) {
        yield put(appActions.apiCallFailed(checkDataOfAddingBatchItemMessage));
        return;
      }
       if (isHoldingAreaListing) {
        let matchingProduct = null;
        products.forEach(product => {
          if (product.sku === listing.sku) {
            matchingProduct = product;
          }
        });
         if (matchingProduct) {
          const qtyIncreased = listing.qty;
          const { qty } = matchingProduct;
           if (qtyIncreased && qtyIncreased > 0) {
            const updatedListing = Object.assign({}, matchingProduct, {
              qty: qty + qtyIncreased
            });
            if (batchMetadata.workflowType === "live") {
              yield put(actions.updateInboundShipmentQuantityAndEditListing(
                updatedListing,
                qtyIncreased,
                isHoldingAreaListing
              ));
            } else {
              yield put(actions.editListing(
                id,
                updatedListing,
                qtyIncreased,
                isHoldingAreaListing
              ));
            }
          }
          return;
        }
      }
       if (listing) {
        currentWorkingListingData = listing;
      }
       if (batchMetadata.workflowType === "private") {
        yield put(actions.tryAddingItemToPrivateBatch(
          currentWorkingListingData,
          isHoldingAreaListing,
          printerDefaults,
        ));
      } else if (batchMetadata.workflowType === "live") {
        yield put(actions.tryAddingItemToLiveBatch(
          currentWorkingListingData,
          isHoldingAreaListing,
          printerDefaults,
        ));
      }
    } catch (err) {
      yield put(appActions.apiCallFailed("Error! Fetching api error"));
      logError(err, {
        tags: {
          exceptionType: "TRY_ADDING_ITEM_TO_BATCH_ERROR",
          batchId: yield select(batchIdSelector),
        }
      });
    }
  })
}

export function* updateCurrentWorkingListing() {
  yield takeLatest(actions.UPDATE_CURRENT_WORKING_LISTING, function*(payload) {
    try {
      const {
        fieldName,
				fieldValue,
      } = payload;
      if (fieldName === "price") {
        yield put(actions.suspendSpeedMode());
				let currentWorkingListingData = yield select(currentWorkingListingDataSelector);
				const data = {
					asin: currentWorkingListingData.asin,
					price: fieldValue,
					weight: currentWorkingListingData.packageWeight ? parseFloat(currentWorkingListingData.packageWeight) : 0,
				}
        yield put(actions.batchItemProjectedProfit(data));
      }
    } catch (err) {
      logError(err, {
        tags: {
          exceptionType: "UPDATE_CURRENT_WORKING_LISTING_ERROR",
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* updateBatchData() {
  yield takeLatest(actions.BATCH_METADATA_UPDATE, function*(payload) {
    try {
      const { data } = payload;
      var update_data = {
        batch_id: yield select(batchIdSelector),
        update_data: data
      }
      const response = yield call(updateBatchDataAPI, update_data);
      if(!response.data.error){
        yield put(actions.batchMetadataUpdateSuccess(data));
      } else {
        yield put(actions.batchMetadataUpdateError());
        yield put(appActions.apiCallFailed("Batch data update error"));
      }
    } catch (err) {
      logError(err, {
        tags: {
          exceptionType: "BATCH_METADATA_UPDATE_ERROR",
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* sbybUserTracking() {
  yield takeLatest(actions.BATCH_SBYB_USER_TRACKING, function*(payload) {
    try {
		const { data } = payload;
		const response = yield call(sbybUserTrackingAPI, data);
		if(response.data.error){
			yield put(actions.sbybUserTrackingSuccess());
		} else {
			yield put(actions.sbybUserTrackingFailed());
		}
    } catch (err) {
		yield put(actions.sbybUserTrackingFailed());
      logError(err, {
        tags: {
          exceptionType: "BATCH_SBYB_USER_TRACKING_ERROR",
          batchId: yield select(batchIdSelector),
        }
      });
    }
  });
}

export function* pricingDataSbybTracking() {
  yield takeLatest(actions.BATCH_PRICING_DATA_SBYB, function*(payload) {
    try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'get', apiEndpoints.competitor_pricing.pricing_data_sbyb, data);
			if(!response.data.error){
				yield put(actions.pricingDataSbybTrackingSuccess(response));
			} else {
				yield put(actions.pricingDataSbybTrackingFailed());
			}
    } catch (err) {
			yield put(actions.pricingDataSbybTrackingFailed());
				logError(err, {
					tags: {
						exceptionType: "BATCH_PRICING_DATA_SBYB_ERROR",
					}
				});
			}
  });
}

export function* pricingDataProfithuntTracking() {
  yield takeLatest(actions.BATCH_PRICING_DATA_PROFITHUNT, function*(payload) {
    try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'get', apiEndpoints.competitor_pricing.pricing_data_profithunt, data);
			if(!response.data.error){
				yield put(actions.pricingDataProfithuntTrackingSuccess(response));
			} else {
				yield put(actions.pricingDataProfithuntTrackingFailed());
			}
    } catch (err) {
			yield put(actions.pricingDataProfithuntTrackingFailed());
				logError(err, {
					tags: {
						exceptionType: "BATCH_PRICING_DATA_PROFITHUNT_ERROR",
					}
				});
			}
  });
}

export function* updateAllBatchListings() {
	yield takeLatest(actions.UPDATE_ALL_BATCH_LISTINGS, function*(payload) {
    try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'post', apiEndpoints.batch.update_all_listings, data);
			if(!response.data.error){
				yield put(actions.updateAllBatchListingsSuccess(data));
			} else {
				yield put(actions.updateAllBatchListingsFailed());
			}
		} catch (err) {
			yield put(actions.updateAllBatchListingsFailed());
				logError(err, {
					tags: {
						exceptionType: "UPDATE_ALL_BATCH_LISTINGS_ERROR",
					}
				});
			}
  });
}

export function* batchItemProjectedProfit() {
  yield takeLatest(actions.BATCH_ITEM_PROJECTED_PROFIT, function*(payload) {
    try {
			const { data } = payload;
			const response = yield call(backendAPICall, 'get', apiEndpoints.competitor_pricing.projected_profit, data);
			if(!response.data.error){
				yield put(actions.batchItemProjectedProfitSuccess(response));
			} else {
				yield put(actions.batchItemProjectedProfitFailed());
			}
    } catch (err) {
			yield put(actions.batchItemProjectedProfitFailed());
				logError(err, {
					tags: {
						exceptionType: "BATCH_ITEM_PROJECTED_PROFIT_ERROR",
					}
				});
			}
  });
}

export function* batchShipmentsGetDistances() {
  yield takeLatest(actions.BATCH_SHIPMENT_GET_DISTANCES, function*(payload) {
    try {
			const response = yield call(backendAPICall, 'post', apiEndpoints.batch.shipment_distance, payload.data);
			if(!response.data.error){
				yield put(actions.batchShipmentsGetDistancesSuccess(response));
			} else {
				yield put(actions.batchShipmentsGetDistancesFailed());
			}
    } catch (err) {
			yield put(actions.batchShipmentsGetDistancesFailed());
				logError(err, {
					tags: {
						exceptionType: "BATCH_SHIPMENT_GET_DISTANCES_ERROR",
					}
				});
			}
  });
}

export function* batchAddToBatchReplenish() {
  yield takeLatest(actions.BATCH_ADD_TO_BATCH_REPLENISH, function*(payload) {
    try {
      const { data } = payload;
			const response = yield call(backendAPICall, 'get', apiEndpoints.competitor_pricing.projected_profit, data.profit);
			if(!response.data.error){
        yield put(actions.batchAddToBatchReplenishSuccess());
        let listing = data.listing;
        if(listing.batchChannel.includes("AMAZON_")){
          listing.totalFeeEstimate = response.data.fba.totalFeeEstimate ? response.data.fba.totalFeeEstimate : 0;
        } else {
          listing.totalFeeEstimate = response.data.fbm.totalFeeEstimate ? response.data.fbm.totalFeeEstimate : 0;
        }
        // signal it is replen item
        listing.signal_replenished_item = true;
        yield put(actions.tryAddingItemToBatch(
          listing,
          null,
          false
        ));
			} else {
				yield put(actions.batchAddToBatchReplenishFailed());
			}
    } catch (err) {
			yield put(actions.batchAddToBatchReplenishFailed());
				logError(err, {
					tags: {
						exceptionType: "BATCH_ADD_TO_BATCH_REPLENISH_ERROR",
					}
				});
			}
  });
}

export function* batchSearchRestrictions() {
  yield takeLatest(actions.BATCH_SEARCH_RESTRICTIONS, function*(payload) {
    try {
      const { data } = payload;
			const response = yield call(backendAPICall, 'get', apiEndpoints.search.restrictions, data);
			if(!response.data.error){
        yield put(actions.batchSearchRestrictionsSuccess(response.data));
			} else {
				yield put(actions.batchSearchRestrictionsFailed());
			}
    } catch (err) {
			yield put(actions.batchSearchRestrictionsFailed());
				logError(err, {
					tags: {
						exceptionType: "BATCH_SEARCH_RESTRICTIONS_ERROR",
					}
				});
			}
  });
}

export function* batchSearchSalesRankRange() {
  yield takeLatest(actions.SEARCH_SALES_RANK_RANGE, function*(payload) {
    try {
      const { data } = payload;
			const response = yield call(searchBatchItemSalesRankRange, data);
			if(!response.data.error){
        yield put(actions.searchSalesRankRangeSuccess(response.data));
			} else {
				yield put(actions.searchSalesRankRangeFailed());
			}
    } catch (err) {
			yield put(actions.searchSalesRankRangeFailed());
				logError(err, {
					tags: {
						exceptionType: "BATCH_SEARCH_SALES_RANK_ERROR",
					}
				});
			}
  });
}

export function* batchUpdateItemAvailabilityFulfillment(){
  yield takeLatest(actions.BATCH_UPDATE_ITEM_AVAILABILITY_FULFILLMENT, function*(payload){
  yield put(actions.batchUpdateItemAvailabilityFulfillmentSuccess(payload.data));
})}

export function* batchUpdateItemPartial(){
  yield takeLatest(actions.BATCH_UPDATE_ITEM_PARTIAL, function*(payload){
  yield put(actions.batchUpdateItemPartialSuccess(payload.data));
})}

export default function* rootSaga() {
  yield all([
    fork(productSearchResultsRequest),
    fork(cancelListingCreationFlow),
    fork(selectProductSearchResultAndInitializeCurrentWorkingListing),
    fork(saveListing),
    fork(saveListingSuccess),
    fork(editListing),
    fork(editListingSuccess),
    fork(deleteListing),
    fork(printListing),
    fork(tryAddingItemToBatch),
    fork(updateCurrentWorkingListing),
		fork(updateBatchData),
		fork(sbybUserTracking),
		fork(pricingDataSbybTracking),
		fork(pricingDataProfithuntTracking),
		fork(updateAllBatchListings),
		fork(batchItemProjectedProfit),
		fork(batchShipmentsGetDistances),
		fork(batchAddToBatchReplenish),
		fork(batchSearchRestrictions),
    fork(batchSearchSalesRankRange),
		fork(batchUpdateItemAvailabilityFulfillment),
		fork(batchUpdateItemPartial),
	]);
}
