import {
    Map
} from 'immutable/dist/immutable'
import actions from './actions'

const initState = new Map({
  boxes: [],
  box_item_added: null,
  box_items_added: null,
  active_box: null,
  fulfillment_inbound_working: false,
  fulfillment_inbound_working_long: false,
  shipments_list: [],
  labels: null,
  shipments_tracking: null,
  tracking_process_id: null,
  items_available_for_inbound: null,
  create_inbound_plan_process_id: null,
  packing_options_process_id: null,
  placement_options_process_id: null,
  items_available_for_inbound_process_id: null,
})

export default function fulfillmentReducer(state = initState, action) {
    switch (action.type) {

        case actions.INBOUND_ERRORS_FIX:
            return state
              .set('fulfillment_inbound_working', true)
        case actions.INBOUND_ERRORS_FIX_SUCCESS:
            return state
              .set('fulfillment_inbound_working', false)
        case actions.INBOUND_ERRORS_FIX_FAILED:
            return state
              .set('fulfillment_inbound_working', false)

         case actions.BOX_ITEM_UPDATE_PREP_TYPES_LOCAL:
            var boxes_item_update_prep = [...state.get("boxes")]
            boxes_item_update_prep = boxes_item_update_prep.map(box => {
              box.items = box.items.map(item => {
                if(item.id === action.data.id){
                  item.batch_data.prep_types = action.data.prep_types;
                }
                return item;
              })
              return box;
            })
            return state
              .set('boxes', [...boxes_item_update_prep])
              .set('fulfillment_inbound_working', false)

        case actions.BOX_ITEM_UPDATE:
            return state
              .set('fulfillment_inbound_working', true)
        case actions.BOX_ITEM_UPDATE_SUCCESS:
            var boxes_item_update = [...state.get("boxes")]
            boxes_item_update = boxes_item_update.map(box => {
              box.items = box.items.map(item => {
                if(item.id === action.data.data.id){
                  return action.data.data;
                }
                return item;
              })
              return box;
            })
            return state
              .set('boxes', [...boxes_item_update])
              .set('fulfillment_inbound_working', false)
        case actions.BOX_ITEM_UPDATE_FAILED:
            return state
              .set('fulfillment_inbound_working', false)

        case actions.BOX_CLEAR_BOX_ITEM_ADDED_ALL:
          return state
            .set('box_items_added', null)

        case actions.BOX_ADD_ITEM_ALL:
            return state
                .set('fulfillment_inbound_working', true)
                .set('box_items_added', null)
        case actions.BOX_ADD_ITEM_ALL_SUCCESS:
            var boxes_add_items = [...state.get("boxes")]
            boxes_add_items = boxes_add_items.map(item => {
              if(item.id === action.data.fulfillment_box_id){
                action.data.data.forEach(box_item => {
                  item.items.push({...box_item})
                })
              }
              return item;
            })
            return state
                .set('boxes', [...boxes_add_items])
                .set('fulfillment_inbound_working', false)
                .set('box_items_added', [...action.data.data])
        case actions.BOX_ADD_ITEM_ALL_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('box_items_added', null)

        case actions.GET_ITEMS_AVAILABLE_FOR_INBOUND:
            return state
                .set('items_available_for_inbound_process_id', null)
        case actions.GET_ITEMS_AVAILABLE_FOR_INBOUND_SUCCESS:
            return state
                .set('items_available_for_inbound_process_id', action.data?.data?.process_id)
        case actions.GET_ITEMS_AVAILABLE_FOR_INBOUND_FAILED:
            return state
                .set('items_available_for_inbound_process_id', null)

        case actions.GET_ITEMS_AVAILABLE_FOR_INBOUND_GET_SUCCESS:
            return state
                .set('items_available_for_inbound', action.data?.data)
                .set('items_available_for_inbound_process_id', action.data?.finished ? null : state.get("items_available_for_inbound_process_id"))
        case actions.GET_ITEMS_AVAILABLE_FOR_INBOUND_GET_FAILED:
            return state
                .set('items_available_for_inbound_process_id', null)

        case actions.BOX_CLEAR_BOX_ITEM_ADDED:
          return state
            .set('box_item_added', null)

        case actions.BOX_UPDATE:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.BOX_UPDATE_SUCCESS:
            var boxes_update = [...state.get("boxes")]
            boxes_update = boxes_update.map(item => {
              if(item.id === action.data.data.id){
                return action.data.data;
              }
              return item;
            })
            return state
                .set('boxes', [...boxes_update])
                .set('fulfillment_inbound_working', false)
        case actions.BOX_UPDATE_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.SHIPMENTS_TRACKING_GET:
            return state
                .set("shipments_tracking", null)
                .set('fulfillment_inbound_working', true)
        case actions.SHIPMENTS_TRACKING_GET_SUCCESS:
            return state
                .set("shipments_tracking", {...action.data.data.boxes})
                .set('fulfillment_inbound_working', false)
        case actions.SHIPMENTS_TRACKING_GET_FAILED:
            return state
                .set("shipments_tracking", null)
                .set('fulfillment_inbound_working', false)

        case actions.SHIPMENTS_TRACKING_UPDATE:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.SHIPMENTS_TRACKING_UPDATE_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
        case actions.SHIPMENTS_TRACKING_UPDATE_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.BOX_DELETE_ITEM:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.BOX_DELETE_ITEM_SUCCESS:
            var boxes_delete_item = [...state.get("boxes")]
            boxes_delete_item = boxes_delete_item.map(item => {
              if(item.id === action.data.data.fulfillment_box_id){
                item.items = item.items.filter(i => i.id !== action.data.data.id);
              }
              return item;
            })
            return state
                .set('boxes', [...boxes_delete_item])
                .set('fulfillment_inbound_working', false)
        case actions.BOX_DELETE_ITEM_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.SHIPMENT_LABELS_CREATE:
            return state
                .set('labels', null)
                .set('fulfillment_inbound_working', true)
        case actions.SHIPMENT_LABELS_CREATE_SUCCESS:
            return state
                .set('labels', {...action.data.data})
                .set('fulfillment_inbound_working', false)
        case actions.SHIPMENT_LABELS_CREATE_FAILED:
            return state
                .set('labels', null)
                .set('fulfillment_inbound_working', false)

        case actions.SHIPMENTS_LIST_GET:
            return state
                .set('shipments_list', null)
                .set('fulfillment_inbound_working', true)
        case actions.SHIPMENTS_LIST_GET_SUCCESS:
            return state
                .set('shipments_list', [...action.data.data])
                .set('fulfillment_inbound_working', false)
        case actions.SHIPMENTS_LIST_GET_FAILED:
            return state
                .set('shipments_list', null)
                .set('fulfillment_inbound_working', false)

        case actions.BOX_DELETE:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.BOX_DELETE_SUCCESS:
            var boxes_delete = [...state.get("boxes")]
            boxes_delete = boxes_delete.filter(item => item.id !== action.data.data.id)
            return state
                .set('boxes', [...boxes_delete])
                .set('active_box', null)
                .set('fulfillment_inbound_working', false)
        case actions.BOX_DELETE_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.CONFIRM_TRANSPORTATION_OPTIONS:
            return state
                .set('fulfillment_inbound_working', true)
                .set('tracking_process_id', null)
        case actions.CONFIRM_TRANSPORTATION_OPTIONS_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
        case actions.CONFIRM_TRANSPORTATION_OPTIONS_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.CONFIRM_DELIVERY_OPTIONS:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.CONFIRM_DELIVERY_OPTIONS_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
        case actions.CONFIRM_DELIVERY_OPTIONS_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.CONFIRM_PLACEMENT_OPTION:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.CONFIRM_PLACEMENT_OPTION_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
        case actions.CONFIRM_PLACEMENT_OPTION_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.GENERATE_TRANSPORTAION_OPTIONS:
            return state
                .set('fulfillment_inbound_working', true)
                .set('fulfillment_inbound_working_long', true)
                .set('tracking_process_id', null)
        case actions.GENERATE_TRANSPORTAION_OPTIONS_SUCCESS:
            return state
                .set('tracking_process_id', action.data?.data)
        case actions.GENERATE_TRANSPORTAION_OPTIONS_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('tracking_process_id', null)

        case actions.GENERATE_TRANSPORTAION_OPTIONS_GET:
            return state
                .set('fulfillment_inbound_working', true)
                .set('fulfillment_inbound_working_long', true)
        case actions.GENERATE_TRANSPORTAION_OPTIONS_GET_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('tracking_process_id', action.data?.data)
        case actions.GENERATE_TRANSPORTAION_OPTIONS_GET_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('tracking_process_id', null)

        case actions.GENERATE_PLACEMENT_OPTIONS:
            return state
                .set('fulfillment_inbound_working', true)
                .set('fulfillment_inbound_working_long', true)
                .set('placement_options_process_id', null)
        case actions.GENERATE_PLACEMENT_OPTIONS_SUCCESS:
            return state
                .set('placement_options_process_id', action.data?.data)
        case actions.GENERATE_PLACEMENT_OPTIONS_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('placement_options_process_id', null)

        case actions.GENERATE_PLACEMENT_OPTIONS_GET:
            return state
                .set('fulfillment_inbound_working', true)
                .set('fulfillment_inbound_working_long', true)
        case actions.GENERATE_PLACEMENT_OPTIONS_GET_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('placement_options_process_id', null)
        case actions.GENERATE_PLACEMENT_OPTIONS_GET_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('placement_options_process_id', null)

        case actions.BOX_PACKING_SET:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.BOX_PACKING_SET_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
        case actions.BOX_PACKING_SET_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.BOX_ADD_ITEM:
            return state
                .set('fulfillment_inbound_working', true)
                .set('box_item_added', null)
        case actions.BOX_ADD_ITEM_SUCCESS:
            var boxes_add_item = [...state.get("boxes")]
            boxes_add_item = boxes_add_item.map(item => {
              if(item.id === action.data.data.fulfillment_box_id){
                const index = item.items.findIndex(i => i.id === action.data.data.id);
                if(index >= 0){
                  item.items[index] = {...action.data.data}
                } else {
                  item.items.unshift({...action.data.data})
                }
              }
              return item;
            })
            return state
                .set('boxes', [...boxes_add_item])
                .set('fulfillment_inbound_working', false)
                .set('box_item_added', {...action.data.data})
        case actions.BOX_ADD_ITEM_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('box_item_added', null)

        case actions.BOXES_LOAD_ALL:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.BOXES_LOAD_ALL_SUCCESS:
            return state
                .set('boxes', [...action.data.data])
                .set('fulfillment_inbound_working', false)
        case actions.BOXES_LOAD_ALL_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.BOX_CREATE:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.BOX_CREATE_SUCCESS:
            var boxes_create = [...state.get("boxes")]
            boxes_create.push(action.data.data)
            return state
                .set('boxes', [...boxes_create])
                .set('fulfillment_inbound_working', false)
        case actions.BOX_CREATE_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.INBOUND_PLAN_CANCEL:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.INBOUND_PLAN_CANCEL_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
        case actions.INBOUND_PLAN_CANCEL_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.CONFIRM_PACKING_OPTION:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.CONFIRM_PACKING_OPTION_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
        case actions.CONFIRM_PACKING_OPTION_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        case actions.GENERATE_PACKING_OPTIONS:
            return state
                .set('fulfillment_inbound_working', true)
                .set('fulfillment_inbound_working_long', true)
                .set('packing_options_process_id', null)
        case actions.GENERATE_PACKING_OPTIONS_SUCCESS:
            return state
                .set('packing_options_process_id', action.data?.data)
        case actions.GENERATE_PACKING_OPTIONS_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('packing_options_process_id', null)

        case actions.GENERATE_PACKING_OPTIONS_GET:
            return state
                .set('fulfillment_inbound_working', true)
                .set('fulfillment_inbound_working_long', true)
        case actions.GENERATE_PACKING_OPTIONS_GET_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('packing_options_process_id', null)
        case actions.GENERATE_PACKING_OPTIONS_GET_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('packing_options_process_id', null)

        case actions.INBOUND_PLAN_CREATE:
            return state
                .set('fulfillment_inbound_working', true)
                .set('fulfillment_inbound_working_long', true)
                .set('create_inbound_plan_process_id', null)
        case actions.INBOUND_PLAN_CREATE_SUCCESS:
            return state
                .set('create_inbound_plan_process_id', action.data?.data)
        case actions.INBOUND_PLAN_CREATE_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('create_inbound_plan_process_id', null)

        case actions.INBOUND_PLAN_CREATE_GET:
            return state
                .set('fulfillment_inbound_working', true)
                .set('fulfillment_inbound_working_long', true)
        case actions.INBOUND_PLAN_CREATE_GET_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('create_inbound_plan_process_id', null)
        case actions.INBOUND_PLAN_CREATE_GET_FAILED:
            return state
                .set('fulfillment_inbound_working', false)
                .set('fulfillment_inbound_working_long', false)
                .set('create_inbound_plan_process_id', null)

        case actions.GET_INBOUND_OPERATION_STATUS:
            return state
                .set('fulfillment_inbound_working', true)
        case actions.GET_INBOUND_OPERATION_STATUS_SUCCESS:
            return state
                .set('fulfillment_inbound_working', false)
        case actions.GET_INBOUND_OPERATION_STATUS_FAILED:
            return state
                .set('fulfillment_inbound_working', false)

        default:
            return state
    }
}
