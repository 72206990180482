import React, { Component } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col, Form, FormGroup, FormText, Label, Input,
  InputGroup, InputGroupAddon, FormFeedback
} from 'reactstrap';
import Select from 'react-select';
import { FaEye, FaEyeSlash } from 'react-icons/lib/fa';
import { validateEmail, validatePhone } from '../../../helpers/utility';
const us_states_json = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States Of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
};

const ca_provinces_json = {
	"AB": "Alberta",
  "BC": "British Columbia",
  "MB": "Manitoba",
  "NB": "New Brunswick",
  "NL": "Newfoundland and Labrador",
  "NS": "Nova Scotia",
  "NT": "Northwest Territories",
  "NU": "Nunavut",
  "ON": "Ontario",
  "PE": "Prince Edward Island",
  "QC": "Québec",
  "SK": "Saskatchewan",
  "YT": "Yukon"
}

const uk_counties = [
  "Aberdeenshire",
  "Anglesey",
  "Angus (Forfarshire)",
  "Antrim",
  "Argyll",
  "Armagh",
  "Avon",
  "Ayrshire",
  "Banffshire",
  "Bedfordshire",
  "Berkshire",
  "Berwickshire",
  "Brecknockshire",
  "Buckinghamshire",
  "Bute",
  "Caernarfonshire",
  "Caithness",
  "Cambridgeshire",
  "Cambridgeshire and Isle of Ely",
  "Cardiganshire",
  "Carmarthenshire",
  "Cheshire",
  "City of Aberdeen",
  "City of Belfast",
  "City of Bristol",
  "City of Derry",
  "City of Dundee",
  "City of Edinburgh",
  "City of Glasgow",
  "City of London",
  "Clackmannanshire",
  "Cleveland",
  "Clwyd",
  "Cornwall",
  "Cromartyshire",
  "Cumberland",
  "Cumbria",
  "Denbighshire",
  "Derbyshire",
  "Devon",
  "Dorset",
  "Down",
  "Dumfriesshire",
  "Dunbartonshire (Dumbarton)",
  "Durham (County Durham)",
  "Dyfed",
  "East Lothian (Haddingtonshire)",
  "East Suffolk",
  "East Sussex",
  "Essex",
  "Fermanagh",
  "Fife",
  "Flintshire",
  "Glamorgan",
  "Gloucestershire",
  "Greater London",
  "Greater Manchester",
  "Gwent",
  "Gwynedd",
  "Hampshire",
  "Hereford and Worcester",
  "Herefordshire",
  "Hertfordshire",
  "Humberside",
  "Huntingdon and Peterborough",
  "Huntingdonshire",
  "Inverness-shire",
  "Isle of Ely",
  "Isle of Wight",
  "Kent",
  "Kincardineshire",
  "Kinross-shire",
  "Kirkcudbrightshire",
  "Lanarkshire",
  "Lancashire",
  "Leicestershire",
  "Lincolnshire",
  "Lincolnshire, Parts of Holland",
  "Lincolnshire, Parts of Kesteven",
  "Lincolnshire, Parts of Lindsey",
  "London",
  "Londonderry",
  "Merionethshire",
  "Merseyside",
  "Mid Glamorgan",
  "Middlesex",
  "Midlothian (County of Edinburgh)",
  "Monmouthshire",
  "Montgomeryshire",
  "Moray (Elginshire)",
  "Nairnshire",
  "Norfolk",
  "North Humberside",
  "North Yorkshire",
  "Northamptonshire",
  "Northumberland",
  "Nottinghamshire",
  "Orkney",
  "Oxfordshire",
  "Peeblesshire",
  "Pembrokeshire",
  "Perthshire",
  "Powys",
  "Radnorshire",
  "Renfrewshire",
  "Ross and Cromarty",
  "Ross-shire",
  "Roxburghshire",
  "Rutland",
  "Selkirkshire",
  "Shetland (Zetland)",
  "Shropshire",
  "Soke of Peterborough",
  "Somerset",
  "South Glamorgan",
  "South Humberside",
  "South Yorkshire",
  "Staffordshire",
  "Stirlingshire",
  "Suffolk",
  "Surrey",
  "Sussex",
  "Sutherland",
  "Tyne and Wear",
  "Tyrone",
  "Warwickshire",
  "West Glamorgan",
  "West Lothian (Linlithgowshire)",
  "West Midlands",
  "West Suffolk",
  "West Sussex",
  "West Yorkshire",
  "Westmorland",
  "Wigtownshire",
  "Wiltshire",
  "Worcestershire",
  "Wrexham",
  "Yorkshire",
  "Yorkshire, East Riding",
  "Yorkshire, North Riding",
  "Yorkshire, West Riding"
];

class EmployeeAddModal extends Component {
  constructor(props) {
    super(props);

		this.state = {
			selectedState: null,
			passwordMode: true,
			privileges : [],
			name: "",
			username: "",
			phone: "",
			city: "",
			email: "",
			password: "",
			password_confirmation: "",
			marketplace: {value: 'US', label: 'US'}
		}
	}

  stateLabelByState = (state)  => {
    if(us_states_json[state]){
      this.setState({marketplace: {value: 'US', label: 'US'}})
      return us_states_json[state];
    } else if(ca_provinces_json[state]){
      this.setState({marketplace: {value: 'CA', label: 'CA'}})
      return ca_provinces_json[state];
    } else if(uk_counties.includes(state)){
      this.setState({marketplace: {value: 'UK', label: 'UK'}})
      return state;
    } else {
      return null;
    }
  }

  UNSAFE_componentWillReceiveProps(np){
    if(np.employee_edit){
      if(!this.state.username && np.isOpen === true){
        this.setState({
          id: np.employee_data.id,
          selectedState: np.employee_data.state
            ? {
              label: this.stateLabelByState(np.employee_data.state),
              value: np.employee_data.state,
            } : null,
          privileges : np.employee_data.permissions,
          name: np.employee_data.business_name,
          username: np.employee_data.username,
          phone: np.employee_data.phone,
          city: np.employee_data.city,
          email: np.employee_data.email,
          password: "",
          password_confirmation: "",
        });
      }
    }
    if(np.isOpen === false){ this.resetState() }
  }

  resetState = () => {
    this.setState({
      selectedState: null,
      passwordMode: true,
      privileges : [],
      name: "",
      username: "",
      phone: "",
      city: "",
      email: "",
      password: "",
      password_confirmation: "",
    })
  }

  checkAllowedInsert = (ignore_pw=false) => {
    const {name, email, password, password_confirmation, phone}  = this.state;
    if(name && email && phone && validateEmail(email) && validatePhone(phone)){
      if(ignore_pw) {
        if(!password && !password_confirmation){
            return false;
        } else {
        if(password && password_confirmation && (password === password_confirmation)){
          return false;
          }
        }
      } else {
        if(password && password_confirmation && (password === password_confirmation)){
          return false;
        }
      }
    }
    return true
  }

  togglePrivilege = (name) => {
    let privileges = this.state.privileges;
    if(privileges.includes(name)) {
      privileges = privileges.filter(p => p !== name);
    } else {
      privileges.push(name);
    }

    this.setState({ privileges: privileges });
  }

  stateChanged = (selected) => {
    this.setState({
      selectedState: selected
    })
  }

  togglePasswordMode = () => {
    this.setState({
      passwordMode: !this.state.passwordMode
    })
  }

  updateState = (key, val) => {
    if (key === "phone" && !validatePhone(val)) {
      val = this.state[key] || "";
    }
    this.setState({ [key]: val });
  }

  saveEmployee = () => {
    this.props.onSave(this.state);
    this.resetState()
  }

  updateEmployee = () => {
    this.props.updateEmployee(this.state);
    this.resetState()
	}

	selectMarketplace = (selected) => {
		this.setState({
			marketplace: selected,
			selectedState: null
		})
	}

	render() {
		const {
			selectedState,
			privileges,
			passwordMode,
			marketplace
		} = this.state;

    const {
      isOpen,
      toggle,
    } = this.props;

		let teritory_options = [];
		let teritory_data = [];

		switch(marketplace.value) {
			case "US":
				teritory_data = us_states_json;
				break;
			case "CA":
				teritory_data = ca_provinces_json;
				break;
			case "UK":
				teritory_data = uk_counties;
				break;
			default:
		}

		if(marketplace.value !== "UK") {
			Object.keys(teritory_data).forEach(key => {
				teritory_options.push({
					value: key, label: teritory_data[key]
				});
			});
		} else {
			uk_counties.map(item =>
				teritory_options.push({
					value: item, label: item
				})
			)
		}

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>{this.props.employee_edit ? "Update Employee data" : "Add a New Employee"}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    value={this.state.username}
                    onChange={e => this.updateState('username', e.target.value)}
                    disabled={this.props.employee_edit}
									/>
								</FormGroup>
							</Col>
							<Col lg={6}>
								<FormGroup>
									<Label>Full Name</Label>
									<Input
										value={this.state.name}
										onChange={e => this.updateState('name', e.target.value)}
									/>
								</FormGroup>
							</Col>
							<Col lg={6}>
								<FormGroup>
									<Label>Mobile Phone Number</Label>
									<Input
										value={this.state.phone}
										onChange={e => this.updateState('phone', e.target.value)}
									/>
								</FormGroup>
							</Col>
							<Col lg={6}>
								<FormGroup>
									<Label>Marketplace</Label>
									<Select
										options={[
                      {value: 'US', label: 'US'},
                      {value: 'CA', label: 'CA'},
                      {value: 'UK', label: 'UK'}
										]}
										value={marketplace}
										onChange={this.selectMarketplace}
										placeholder="Select marketplace..."
										clearable={false}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col lg="6">
								<FormGroup>
									<Label>City</Label>
									<Input
										value={this.state.city}
										onChange={e => this.updateState('city', e.target.value)}
									/>
								</FormGroup>
							</Col>
							<Col lg="6">
								<FormGroup>
									<Label>
                    {marketplace.value === "US"
                        ? "State"
                        : marketplace.value === "CA"
                          ? "Province"
                          : "County"
                    }
									</Label>
									<Select
										value={selectedState}
										options={teritory_options}
										onChange={this.stateChanged}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col lg="6">
								<FormGroup>
									<Label>Password</Label>
									<InputGroup>
										<Input
											type={passwordMode ? "password" : "text"}
											autoComplete="new-password"
											value={this.state.password}
											onChange={e => this.updateState('password', e.target.value)}
										/>
										<InputGroupAddon addonType="append">
											<Button color="secondary" onClick={this.togglePasswordMode}>
												{passwordMode ? <FaEye /> : <FaEyeSlash />}
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</FormGroup>
							</Col>
							<Col lg="6">
								<FormGroup>
									<Label>Confirm Password</Label>
									<InputGroup>
										<Input
											type={passwordMode ? "password" : "text"}
											autoComplete="new-password"
											value={this.state.password_confirmation}
											onChange={e => this.updateState('password_confirmation', e.target.value)}
										/>
										<InputGroupAddon addonType="append">
											<Button color="secondary" onClick={this.togglePasswordMode}>
												{passwordMode ? <FaEye /> : <FaEyeSlash />}
											</Button>
										</InputGroupAddon>
									</InputGroup>
									{this.state.password_confirmation !== "" && this.state.password_confirmation !== this.state.password &&
										<FormText color="danger">Password don't match</FormText>
									}
								</FormGroup>
							</Col>
						</Row>
						<FormGroup>
							<Label>Email</Label>
							<Input
								type="email"
								value={this.state.email}
								onChange={e => this.updateState('email', e.target.value)}
                disabled={this.props.employee_edit}
              />
              <FormText color="muted">
                We will send activation link to this email
              </FormText>
              {!validateEmail(this.state.email) && this.state.email && (
                <FormFeedback>
                  Email is Incorrect.
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label>This employee can</Label>
              <Row>
                <Col lg="6">
                  <CheckButton
                    name="BATCH_CREATE"
                    label="Create Batches"
                    collection={privileges}
                    toggle={this.togglePrivilege}
                  />
                </Col>
                <Col lg="6">
                  <CheckButton
                    name="INVENTORY_EDIT"
                    label="Edit Inventory"
                    collection={privileges}
                    toggle={this.togglePrivilege}
                  />
                </Col>
                <Col lg="6">
                  <CheckButton
                    name="BOX"
                    label="Fulfillment"
                    collection={privileges}
                    toggle={this.togglePrivilege}
                  />
                </Col>
                <Col lg="6">
                  <CheckButton
                    name="CONDITION"
                    label="Condition Notes"
                    collection={privileges}
                    toggle={this.togglePrivilege}
                  />
                </Col>
                <Col lg="6">
                  <CheckButton
                    name="EBAY_CROSS"
                    label="eBay cross list"
                    collection={privileges}
                    toggle={this.togglePrivilege}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          {this.props.employee_edit ?
            <Button
              color="success"
              onClick={e => this.updateEmployee()}
              disabled={this.checkAllowedInsert(true) || this.props.employees_loading}
            >Update employee data</Button>
          :
            <Button
              color="success"
              onClick={e => this.saveEmployee()}
              disabled={this.checkAllowedInsert() || this.props.employees_loading}
            >Add and send activation email</Button>
          }
        </ModalFooter>
      </Modal>
    );
  }
}

const CheckButton = props => (
  <div className={`check check-btn mb-3 ${props.collection.includes(props.name) ? "active" : ""}`}>
    <label className="container-check">{props.label}
      <input
        name={props.name}
        type="checkbox"
        defaultChecked={props.collection.includes(props.name) ? "checked" : ""}
        onChange={(e) => props.toggle(e.currentTarget.name)}
      />
      <span className="checkmark" />
    </label>
  </div>
);

export default EmployeeAddModal;
