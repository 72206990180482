import React from 'react';
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FaExclamationTriangle } from 'react-icons/lib/fa';
import appActions from '../../../redux/app/actions';
import fulfillmentActions from '../../../redux/fulfillment/actions';
import batchActions from '../../../redux/batch/actions';

const {
  appPushErrorMessageList,
  appErrorMessageClose,
} = appActions;

const {
  fulfillmentInboundErrorsFix,
} = fulfillmentActions;

const {
  batchUpdateItemPartial,
} = batchActions;

class ErrorModal extends React.Component {

  displayImage = (error) => {
    const { products } = this.props;
    var product = null;
    products.forEach(item => {
      if(
        error?.details
          && error.details.toLowerCase().includes(item.sku.toLowerCase())
      ){
        product = item;
      }
      if(
        error?.message
        && error.message.toLowerCase().includes(item.sku.toLowerCase())
      ){
          product = item;
      }
    })

    if(product){
      return (
        <img
          src={product?.imageUrl}
          alt=""
          style={{width: 50, height: 60, objectFit: 'contain'}}
        />
      )
    }
    return null;
  }

  render() {
    const {
      isOpen,
      toggle,
      errors,
      batchMetadata,
      fulfillment_inbound_working,
    } = this.props;

    const error_data = errors ? errors : this.props.error_mesages_list;

    return (
      <Modal isOpen={isOpen} toggle={toggle ? toggle : this.props.appErrorMessageClose} size="lg">
        <ModalHeader toggle={toggle}>Error Messages ({errors ? errors?.length : this.props.error_mesages_list?.length})</ModalHeader>
        <ModalBody style={{maxHeight: '70vh', overflowY: 'auto'}} className="scrollable">
          {error_data?.map((error, index) => (
            <div key={index} className="alert alert-danger mb-2 rounded">
              <div className="d-flex align-items-start gap-3">
                {this.displayImage(error)}
                <div className="d-flex align-items-start gap-2">
                  <FaExclamationTriangle style={{width: 26}} />
                  <div style={{lineHeight: 1.2}}>
                    {error?.details && <h6 className="alert-heading mb-1">{error?.details}</h6>}
                    <p className="mb-0">{error?.message}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={toggle ? toggle : this.props.appErrorMessageClose}
            disabled={fulfillment_inbound_working}
          >Close</Button>
          <Button
            color="primary"
            onClick={() => {
              this.props.fulfillmentInboundErrorsFix(
                {
                  batch_id: batchMetadata?.id,
                  errors: error_data,
                },
                [
                  {
                    data: null,
                    run: toggle
                      ? toggle
                      : this.props.appErrorMessageClose
                  },
                  {
                    action: "data",
                    run: this.props.batchUpdateItemPartial,
                  }
                ]
              )
            }}
            disabled={fulfillment_inbound_working}
          >Try To Fix Errors</Button>
        </ModalFooter>
      </Modal>
    )
  }
};

export default connect(
  state => ({
    batchMetadata: state.Batch.get("batchMetadata"),
    products: state.Batch.get("products"),
    error_message_display: state.App.get("error_message_display"),
    error_mesages_list: state.App.get("error_mesages_list"),
    fulfillment_inbound_working: state.Fulfillment.get("fulfillment_inbound_working"),
  }),
  {
    appPushErrorMessageList,
    appErrorMessageClose,
    fulfillmentInboundErrorsFix,
    batchUpdateItemPartial,
  }
)(ErrorModal)
