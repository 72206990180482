import {all, takeLatest, put, fork, call} from 'redux-saga/effects';
import actions from './actions'
import batchActions from "../batch/actions";
import appActions from "../app/actions";
import { backendAPICall, apiEndpoints } from '../../helpers/backend/api/client';
import { logError, openInNewTab } from "../../helpers/utility";

export function* fulfillmentInoundPlanCreate() {
  yield takeLatest('INBOUND_PLAN_CREATE', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_plan, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.fulfillmentInoundPlanCreateSuccess({data: response?.data?.data?.process_id}))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentInoundPlanCreateFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentInoundPlanCreateFailed());
        logError(error, {
          tags: {
            exceptionType: "INBOUND_PLAN_CREATE_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentInoundPlanCreateGet() {
  yield takeLatest('INBOUND_PLAN_CREATE_GET', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.inbound_plan, data);
      if (response.status === 200 && !response?.data?.error){
          if(response?.data?.finished){
            if(!response?.data?.data?.error){
              if(response?.data?.data?.data){
                yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data?.data}))
              }
            } else {
              if(response?.data?.data?.error_message_list_display){
                yield put(appActions.appPushErrorMessageList(response?.data?.data?.error_messages_list));
              } else {
                if(response?.data?.data?.error){
                  yield put(appActions.apiCallFailed(response.data.data.error));
                };
              }
            }
            yield put(actions.fulfillmentInoundPlanCreateGetSuccess());
          }
      } else {
        if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
        yield put(actions.fulfillmentInoundPlanCreateGetFailed());
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
      yield put(actions.fulfillmentInoundPlanCreateGetFailed());
      logError(error, {
        tags: {
          exceptionType: "INBOUND_PLAN_CREATE_GET_ERROR"
        }
      });
    }
  })
}

export function* fulfillmentInoundPlanCancel() {
  yield takeLatest('INBOUND_PLAN_CANCEL', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'delete', apiEndpoints.fulfillment.inbound_plan, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.fulfillmentInoundPlanCancelSuccess())
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentInoundPlanCancelFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentInoundPlanCancelFailed());
        logError(error, {
          tags: {
            exceptionType: "INBOUND_PLAN_CANCEL_ERROR"
          }
        });
      }
  })
}

export function* getFulfillmentInboundOperationStatus() {
  yield takeLatest('GET_INBOUND_OPERATION_STATUS', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.inbound_operation_status, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.getFulfillmentInboundOperationStatusSuccess())
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.getFulfillmentInboundOperationStatusFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.getFulfillmentInboundOperationStatusFailed());
        logError(error, {
          tags: {
            exceptionType: "GET_INBOUND_OPERATION_STATUS_ERROR"
          }
        });
      }
  })
}

export function* generatePackingOptions() {
  yield takeLatest('GENERATE_PACKING_OPTIONS', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_packing, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.generatePackingOptionsSuccess({data: response?.data?.data?.process_id}))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.generatePackingOptionsFailed());
          if(response?.data?.error_message_list_display){
            yield put(appActions.appPushErrorMessageList(response?.data?.error_messages_list));
          }
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.generatePackingOptionsFailed());
        logError(error, {
          tags: {
            exceptionType: "GENERATE_PACKING_OPTIONS_ERROR"
          }
        });
      }
  })
}

export function* generatePackingOptionsGet() {
  yield takeLatest('GENERATE_PACKING_OPTIONS_GET', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.inbound_packing, data);
      if (response.status === 200 && !response?.data?.error){
          if(response?.data?.finished){
            if(!response?.data?.data?.error){
              if(response?.data?.data?.data){
                yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data?.data}))
              }
            } else {
              if(response?.data?.data?.error_message_list_display){
                yield put(appActions.appPushErrorMessageList(response?.data?.data?.error_messages_list));
              } else {
                if(response?.data?.data?.error){
                  yield put(appActions.apiCallFailed(response.data.data.error));
                };
              }
            }
            yield put(actions.generatePackingOptionsGetSuccess());
          }
      } else {
        if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
        yield put(actions.generatePackingOptionsGetFailed());
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
      yield put(actions.generatePackingOptionsGetFailed());
      logError(error, {
        tags: {
          exceptionType: "GENERATE_PACKING_OPTIONS_GET_ERROR"
        }
      });
    }
  })
}

export function* confirmPackingOption() {
  yield takeLatest('CONFIRM_PACKING_OPTION', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'put', apiEndpoints.fulfillment.inbound_packing, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.confirmPackingOptionSuccess())
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.confirmPackingOptionFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.confirmPackingOptionFailed());
        logError(error, {
          tags: {
            exceptionType: "CONFIRM_PACKING_OPTION_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxCreate() {
  yield takeLatest('BOX_CREATE', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.box, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentBoxCreateSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxCreateFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentBoxCreateFailed());
        logError(error, {
          tags: {
            exceptionType: "BOX_CREATE_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxesLoadAll() {
  yield takeLatest('BOXES_LOAD_ALL', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.box_all, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentBoxesLoadAllSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxesLoadAllFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentBoxesLoadAllFailed());
        logError(error, {
          tags: {
            exceptionType: "BOXES_LOAD_ALL_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxAddItem() {
  yield takeLatest('BOX_ADD_ITEM', function* (payload) {
    const { data, clearAction } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.box_item, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentBoxAddItemSuccess(response?.data))
          clearAction();
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxAddItemFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error" + error));
        yield put(actions.fulfillmentBoxAddItemFailed());
        logError(error, {
          tags: {
            exceptionType: "BOX_ADD_ITEM_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxPackingSet() {
  yield takeLatest('BOX_PACKING_SET', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_packing_set, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.fulfillmentBoxPackingSetSuccess())
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxPackingSetFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentBoxPackingSetFailed());
        logError(error, {
          tags: {
            exceptionType: "BOX_PACKING_SET_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentGeneratePlacementOptions() {
  yield takeLatest('GENERATE_PLACEMENT_OPTIONS', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_placement, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.fulfillmentGeneratePlacementOptionsSuccess({data: response?.data?.data?.process_id}))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentGeneratePlacementOptionsFailed());
          if(response?.data?.error_message_list_display){
            yield put(appActions.appPushErrorMessageList(response?.data?.error_messages_list));
          }
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentGeneratePlacementOptionsFailed());
        logError(error, {
          tags: {
            exceptionType: "GENERATE_PLACEMENT_OPTIONS_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentGeneratePlacementOptionsGet() {
  yield takeLatest('GENERATE_PLACEMENT_OPTIONS_GET', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.inbound_placement, data);
      if (response.status === 200 && !response?.data?.error){
          if(response?.data?.finished){
            if(!response?.data?.data?.error){
              if(response?.data?.data?.data){
                yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data?.data}))
              }
            } else {
              if(response?.data?.data?.error_message_list_display){
                yield put(appActions.appPushErrorMessageList(response?.data?.data?.error_messages_list));
              } else {
                if(response?.data?.data?.error){
                  yield put(appActions.apiCallFailed(response.data.data.error));
                };
              }
            }
            yield put(actions.fulfillmentGeneratePlacementOptionsGetSuccess());
          }
      } else {
        if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
        yield put(actions.fulfillmentGeneratePlacementOptionsGetFailed());
      }
    } catch (error) {
      yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
      yield put(actions.fulfillmentGeneratePlacementOptionsGetFailed());
      logError(error, {
        tags: {
          exceptionType: "GENERATE_PLACEMENT_OPTIONS_GET_ERROR"
        }
      });
    }
  })
}

export function* fulfillmentGenerateTransportationOptions() {
  yield takeLatest('GENERATE_TRANSPORTAION_OPTIONS', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_transportation, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.fulfillmentGenerateTransportationOptionsSuccess({data: response?.data?.data?.process_id}))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data?.error))};
          yield put(actions.fulfillmentGenerateTransportationOptionsFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentGenerateTransportationOptionsFailed());
        logError(error, {
          tags: {
            exceptionType: "GENERATE_TRANSPORTAION_OPTIONS_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentGenerateTransportationOptionsGet() {
  yield takeLatest('GENERATE_TRANSPORTAION_OPTIONS_GET', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.inbound_transportation, data);
      if (response.status === 200 && !response?.data?.error){
          if(response?.data?.finished){
            yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data?.data}))
            yield put(actions.fulfillmentGenerateTransportationOptionsGetSuccess({data: response?.data?.data?.process_id}))
          }
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentGenerateTransportationOptionsGetFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentGenerateTransportationOptionsGetFailed());
        logError(error, {
          tags: {
            exceptionType: "GENERATE_TRANSPORTAION_OPTIONS_GET_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentConfirmPlacementOption() {
  yield takeLatest('CONFIRM_PLACEMENT_OPTION', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'put', apiEndpoints.fulfillment.inbound_placement, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.fulfillmentConfirmPlacementOptionSuccess())
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentConfirmPlacementOptionFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentConfirmPlacementOptionFailed());
        logError(error, {
          tags: {
            exceptionType: "CONFIRM_PLACEMENT_OPTION_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentConfirmDeliveryOptions() {
  yield takeLatest('CONFIRM_DELIVERY_OPTIONS', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_delivery_confirm, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.fulfillmentConfirmDeliveryOptionsSuccess())
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentConfirmDeliveryOptionsFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentConfirmDeliveryOptionsFailed());
        logError(error, {
          tags: {
            exceptionType: "CONFIRM_DELIVERY_OPTIONS_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentConfirmTransportationOptions() {
  yield takeLatest('CONFIRM_TRANSPORTATION_OPTIONS', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_transportation_confirm, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(batchActions.batchMetadataUpdateSuccess({...response?.data?.data}))
          yield put(actions.fulfillmentConfirmTransportationOptionsSuccess())
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentConfirmTransportationOptionsFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error" + error));
        yield put(actions.fulfillmentConfirmTransportationOptionsFailed());
        logError(error, {
          tags: {
            exceptionType: "CONFIRM_TRANSPORTATION_OPTIONS_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxDelete() {
  yield takeLatest('BOX_DELETE', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'delete', apiEndpoints.fulfillment.box, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentBoxDeleteSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxDeleteFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentBoxDeleteFailed());
        logError(error, {
          tags: {
            exceptionType: "BOX_DELETE_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentShipmentsListGet() {
  yield takeLatest('SHIPMENTS_LIST_GET', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.inbound_shipments_list, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentShipmentsListGetSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentShipmentsListGetFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentShipmentsListGetFailed());
        logError(error, {
          tags: {
            exceptionType: "SHIPMENTS_LIST_GET_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentGetLabels() {
  yield takeLatest('SHIPMENT_LABELS_CREATE', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_labels, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentGetLabelsSuccess(response?.data))
          openInNewTab(response?.data?.data?.DownloadURL)
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentGetLabelsFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentGetLabelsFailed());
        logError(error, {
          tags: {
            exceptionType: "SHIPMENT_LABELS_CREATE_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxDeleteItem() {
  yield takeLatest('BOX_DELETE_ITEM', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'delete', apiEndpoints.fulfillment.box_item, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentBoxDeleteItemSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxDeleteItemFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentBoxDeleteItemFailed());
        logError(error, {
          tags: {
            exceptionType: "BOX_DELETE_ITEM_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentShipmentsTrackingUpdate() {
  yield takeLatest('SHIPMENTS_TRACKING_UPDATE', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_shipments_tracking, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentShipmentsTrackingUpdateSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentShipmentsTrackingUpdateFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentShipmentsTrackingUpdateFailed());
        logError(error, {
          tags: {
            exceptionType: "SHIPMENTS_TRACKING_UPDATE_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentShipmentsTrackingGet() {
  yield takeLatest('SHIPMENTS_TRACKING_GET', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.inbound_shipments_tracking, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentShipmentsTrackingGetSuccess(response?.data))
          yield put(batchActions.batchMetadataUpdateSuccess({inbound_shipments_data: response?.data?.data?.inbound_shipments_data}))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentShipmentsTrackingGetFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentShipmentsTrackingGetFailed());
        logError(error, {
          tags: {
            exceptionType: "SHIPMENTS_TRACKING_GET_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxUpdate() {
  yield takeLatest('BOX_UPDATE', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'put', apiEndpoints.fulfillment.box, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentBoxUpdateSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxUpdateFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentBoxUpdateFailed());
        logError(error, {
          tags: {
            exceptionType: "BOX_UPDATE_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxItemUpdate() {
  yield takeLatest('BOX_ITEM_UPDATE', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'put', apiEndpoints.fulfillment.box_item, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentBoxItemUpdateSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxItemUpdateFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentBoxItemUpdateFailed());
        logError(error, {
          tags: {
            exceptionType: "BOX_ITEM_UPDATE_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentClearBoxItemAdded() {
  yield takeLatest('BOX_CLEAR_BOX_ITEM_ADDED', function* (payload) {});
}

export function* fulfillmentGetItemsAvailableForInbound() {
  yield takeLatest('GET_ITEMS_AVAILABLE_FOR_INBOUND', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_plan_items_available, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentGetItemsAvailableForInboundSuccess(response?.data));
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentGetItemsAvailableForInboundFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentGetItemsAvailableForInboundFailed());
        logError(error, {
          tags: {
            exceptionType: "GET_ITEMS_AVAILABLE_FOR_INBOUND_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentGetItemsAvailableForInboundGet() {
  yield takeLatest('GET_ITEMS_AVAILABLE_FOR_INBOUND_GET', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'get', apiEndpoints.fulfillment.inbound_plan_items_available, data);
        if (response.status === 200 && !response?.data?.error) {
          if(response?.data?.finished){
            yield put(actions.fulfillmentGetItemsAvailableForInboundGetSuccess({...response?.data}));
            yield put(batchActions.batchUpdateItemAvailabilityFulfillment(response?.data));
          }
        } else {
          yield put(actions.fulfillmentGetItemsAvailableForInboundGetFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentGetItemsAvailableForInboundGetFailed());
        logError(error, {
          tags: {
            exceptionType: "GET_ITEMS_AVAILABLE_FOR_INBOUND_GET_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentBoxAddItemAll() {
  yield takeLatest('BOX_ADD_ITEM_ALL', function* (payload) {
    const { data } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.box_item_all, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentBoxAddItemAllSuccess(response?.data))
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentBoxAddItemAllFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentBoxAddItemAllFailed());
        logError(error, {
          tags: {
            exceptionType: "BOX_ADD_ITEM_ALL_ERROR"
          }
        });
      }
  })
}

export function* fulfillmentClearBoxItemAddedAll() {
  yield takeLatest('BOX_CLEAR_BOX_ITEM_ADDED_ALL', function* (payload) {});
}

export function* fulfillmentBoxItemUpdatePrepTypesLocal() {
  yield takeLatest('BOX_ITEM_UPDATE_PREP_TYPES_LOCAL', function* (payload) {});
}

export function* fulfillmentInboundErrorsFix() {
  yield takeLatest('INBOUND_ERRORS_FIX', function* (payload) {
    const { data, additional_actions } = payload;
    try {
      const response = yield call(backendAPICall, 'post', apiEndpoints.fulfillment.inbound_errors_fix, data);
        if (response.status === 200 && !response?.data?.error) {
          yield put(actions.fulfillmentInboundErrorsFixSuccess(response?.data))
          if(additional_actions){
            additional_actions.forEach(action => {
              if(action.action === "data"){
                action.run(response?.data)
              } else {
                action.run()
              }
            })
          }
        } else {
          if(response?.data?.error){yield put(appActions.apiCallFailed(response.data.error))};
          yield put(actions.fulfillmentInboundErrorsFixFailed());
        }
      } catch (error) {
        yield put(appActions.apiCallFailed("Error! Fulfillment api error"));
        yield put(actions.fulfillmentInboundErrorsFixFailed());
        logError(error, {
          tags: {
            exceptionType: "INBOUND_ERRORS_FIX_ERROR"
          }
        });
      }
  })
}

export default function* rootSaga() {
  yield all([
    fork(fulfillmentInoundPlanCreate),
    fork(fulfillmentInoundPlanCreateGet),
    fork(fulfillmentInoundPlanCancel),
    fork(getFulfillmentInboundOperationStatus),
    fork(generatePackingOptions),
    fork(generatePackingOptionsGet),
    fork(confirmPackingOption),
    fork(fulfillmentBoxCreate),
    fork(fulfillmentBoxesLoadAll),
    fork(fulfillmentBoxAddItem),
    fork(fulfillmentBoxAddItemAll),
    fork(fulfillmentBoxPackingSet),
    fork(fulfillmentGeneratePlacementOptions),
    fork(fulfillmentGeneratePlacementOptionsGet),
    fork(fulfillmentGenerateTransportationOptions),
    fork(fulfillmentGenerateTransportationOptionsGet),
    fork(fulfillmentConfirmPlacementOption),
    fork(fulfillmentConfirmDeliveryOptions),
    fork(fulfillmentConfirmTransportationOptions),
    fork(fulfillmentBoxDelete),
    fork(fulfillmentShipmentsListGet),
    fork(fulfillmentGetLabels),
    fork(fulfillmentBoxDeleteItem),
    fork(fulfillmentShipmentsTrackingUpdate),
    fork(fulfillmentShipmentsTrackingGet),
    fork(fulfillmentBoxUpdate),
    fork(fulfillmentClearBoxItemAdded),
    fork(fulfillmentGetItemsAvailableForInbound),
    fork(fulfillmentGetItemsAvailableForInboundGet),
    fork(fulfillmentClearBoxItemAddedAll),
    fork(fulfillmentBoxItemUpdate),
    fork(fulfillmentBoxItemUpdatePrepTypesLocal),
    fork(fulfillmentInboundErrorsFix),
  ])
}
