import React, { Component } from 'react'
import Select from 'react-select'

export const options = [
  {value: 'ITEM_BLACK_SHRINKWRAP', label: 'ITEM_BLACK_SHRINKWRAP'},
  {value: 'ITEM_BLANKSTK', label: 'ITEM_BLANKSTK'},
  {value: 'ITEM_BOXING', label: 'ITEM_BOXING'},
  {value: 'ITEM_BUBBLEWRAP', label: 'ITEM_BUBBLEWRAP'},
  {value: 'ITEM_CAP_SEALING', label: 'ITEM_CAP_SEALING'},
  {value: 'ITEM_DEBUNDLE', label: 'ITEM_DEBUNDLE'},
  {value: 'ITEM_HANG_GARMENT', label: 'ITEM_HANG_GARMENT'},
  {value: 'ITEM_LABELING', label: 'ITEM_LABELING'},
  {value: 'ITEM_NO_PREP', label: 'ITEM_NO_PREP'},
  {value: 'ITEM_POLYBAGGING', label: 'ITEM_POLYBAGGING'},
  {value: 'ITEM_RMOVHANG', label: 'ITEM_RMOVHANG'},
  {value: 'ITEM_SETCREAT', label: 'ITEM_SETCREAT'},
  {value: 'ITEM_SETSTK', label: 'ITEM_SETSTK'},
  {value: 'ITEM_SIOC', label: 'ITEM_SIOC'},
  {value: 'ITEM_SUFFOSTK', label: 'ITEM_SUFFOSTK'},
  {value: 'ITEM_TAPING', label: 'ITEM_TAPING'},
]


class SelectPrepType extends Component {
  render() {
    const { onChange, value, options_available } = this.props
    return (
      <Select
        options={options_available
          ? options.filter(item => options_available.includes(item.value))
          : options
        }
        onChange={selected => onChange(selected)}
        value={value
          ? options.filter(item => value.split(",").includes(item.value))
          : null
        }
        classNames={{
          container: () => 'min-w-60',
          menu: () => 'min-w-60',
        }}
        multi
        isMulti
      />
    )
  }
}

export default SelectPrepType
